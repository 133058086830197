<template>
  <v-card :loading="loading" light class="ma-4">
    <v-card-title v-if="!!formulario" class="grey lighten-5">
      <span class="headline primary--text"> {{formulario.nome}} </span>
      <v-spacer></v-spacer>
      <v-tooltip open-delay="800" top v-if="possuiPermissaoEditarHistoriaClinica">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" outlined color="primary"
            rounded icon @click="openHistoriaClinicaEdit()"
          ><v-icon>mdi-pencil</v-icon></v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>
    </v-card-title>
    <exame-historia-clinica-create-edit v-model="exameHistoriaClinicaSelected" @objectReturn="onSaveHistoriaClinica" />
    <div class="grey lighten-5" v-html="formularioHtml"></div>
  </v-card>
</template>

<script>
import { isAdminAuxiliar, isAdminClient, isAdminMaster } from '../../../seguranca';
import ExameHistoriaClinicaCreateEdit from '../ExameHistoriaClinicaCreateEdit.vue';

export default {
  components: { ExameHistoriaClinicaCreateEdit },
  name: "historia-clinica",
  props: {
    exame: {
      type: Object,
      required: true,
    },
  },
  computed: {
    possuiPermissaoEditarHistoriaClinica() {
      return isAdminMaster() || isAdminClient() || isAdminAuxiliar()
    },
  },
  data() {
    return {
      formulario: null,
      formularioHtml: null,
      loading: false,
      exameHistoriaClinicaSelected: null
    };
  },
  methods: {
    getFormulario() {
      const self = this
      const formularioId = self.exame?.formularioExameHistoriaClinica?.formulario?._id

      if (!formularioId) return

      self.$http.get(`/forms/${formularioId}`).then(response => {
        self.formulario = response.data
      })
    },
    getFormularioHtml() {
      const self = this
      const formularioId = self.exame?.formularioExameHistoriaClinica?.formulario?._id

      if (!formularioId) return
      
      self.loading = true
      self.$http.get(`/report/form/preview/${formularioId}`)
        .then((response) => {
          self.loading = false
          self.formularioHtml = response.data;
        })
        .catch((e) => {
          console.error(e);
          self.loading = false
        });
    },
    openHistoriaClinicaEdit() {
      if (this.exame?.formularioExameHistoriaClinica?.id) {
        this.exameHistoriaClinicaSelected = this.exame
      }
    },
    onSaveHistoriaClinica(value) {
      value
      this.getFormularioHtml()
    },
  },
  created() {
    this.getFormulario()
    this.getFormularioHtml()
  },
}
</script>
