<template>
  <v-container id="container-exame-sidebar" class="ma-0 pa-0">
    <v-card flat id="header-exame-sidebar">
      <v-card-title class="headline primary--text">
        <v-tooltip open-delay="800" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon outlined color="primary" class="mr-2" v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>  
          </template>
          Ocultar
        </v-tooltip>
        {{ $_.startCase(exame.solicitacao.paciente.nome.toLowerCase()) }}
        <v-spacer></v-spacer>
        {{idade }} anos
      </v-card-title>
      <v-card-subtitle>
        <div v-if="exame.tipoExame">{{ exame.tipoExame.modalidade.nome }} {{ exame.tipoExame.nome }}</div>
        <div>{{ moment(exame.data).format('DD/MM/YYYY HH:mm') }}</div>
      </v-card-subtitle>

      <v-card rounded outlined class="ma-0" flat>
        <v-card-text class="px-3 pt-2 pb-1">
          <v-btn :color="tabColor('dados-exame')" elevation="0" class="mr-1 mb-1" small @click="tab = 'dados-exame'">Dados
            Exame</v-btn>
          <v-btn :color="tabColor('laudo-exame')" elevation="0" class="mr-1 mb-1" small @click="tab = 'laudo-exame'"
            v-if="[4,5,6].includes(exame.statusExame.id) && exame.formularioExameLaudo">Laudo</v-btn>
          <v-btn :color="tabColor('imagens-exame')" v-if="exame.study && exame.study.dataEnvioJpg" elevation="0" class="mr-1 mb-1" small
            @click="tab = 'imagens-exame'">Imagens</v-btn>
          <v-btn :color="tabColor('historia-clinica')" elevation="0" v-if="exame.formularioExameHistoriaClinica" class="mr-1 mb-1" small
            @click="tab = 'historia-clinica'">Historia Clinica</v-btn>
          <v-btn :color="tabColor('outros-exames')" elevation="0" class="mr-1 mb-1" small
            @click="tab = 'outros-exames'">Outros Exames</v-btn>
          <v-btn :color="tabColor('reconvocacao-preview')" elevation="0" class="mr-1 mb-1" small @click="tab = 'reconvocacao-preview'"
            v-if="exame.formularioExameReconvocacao">Reconvocação</v-btn>
          <v-btn :color="tabColor('pendencia-preview')" elevation="0" class="mr-1 mb-1" small @click="tab = 'pendencia-preview'"
            v-if="exame.formularioExamePendencia">Pendência</v-btn>
          <v-btn :color="tabColor('revisao-preview')" elevation="0" class="mr-1 mb-1" small @click="tab = 'revisao-preview'"
            v-if="exame.formularioExameRevisao">Revisão</v-btn>
        </v-card-text>
      </v-card>

    </v-card>
    <v-card flat class="ma-0">
      <v-slide-x-transition>
        <dados-exame v-if="tab == 'dados-exame'" :exame="exame"></dados-exame>
      </v-slide-x-transition>

      <v-slide-x-transition>
        <laudo-preview v-if="tab == 'laudo-exame'" :exame="exame"></laudo-preview>
      </v-slide-x-transition>

      <v-slide-x-transition>
        <imagens-exame v-if="tab == 'imagens-exame'" :exame="exame"></imagens-exame>
      </v-slide-x-transition>
      <v-slide-x-transition>
        <historia-clinica v-if="tab == 'historia-clinica'" :exame="exame" />
      </v-slide-x-transition>
      <v-slide-x-reverse-transition>
        <exames-paciente-list v-if="tab == 'outros-exames'" :exame="exame"></exames-paciente-list>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <formulario-exame-preview v-if="tab == 'reconvocacao-preview'"
          :formularioExame="exame.formularioExameReconvocacao"
          :askColor="'blue lighten-5'"
          :answerColor="'blue lighten-4'"
        ></formulario-exame-preview>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <formulario-exame-preview v-if="tab == 'pendencia-preview'"
          :formularioExame="exame.formularioExamePendencia"
          :askColor="'purple lighten-5'"
          :answerColor="'purple lighten-4'"
        ></formulario-exame-preview>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <formulario-exame-preview v-if="tab == 'revisao-preview'"
          :formularioExame="exame.formularioExameRevisao"
          :askColor="'orange lighten-5'"
          :answerColor="'orange lighten-4'"
        ></formulario-exame-preview>
      </v-slide-x-reverse-transition>
    </v-card>
  </v-container>
</template>

<script>
import ExamesPacienteList from "./sidebar/ExamesPacienteList.vue";
import DadosExame from "./sidebar/DadosExame.vue";
import ImagensExame from "./sidebar/ImagensExame.vue";
import moment from "moment";
import LaudoPreview from './sidebar/LaudoPreview.vue';
import HistoriaClinica from './sidebar/HistoriaClinica.vue';
import FormularioExamePreview from './sidebar/FormularioExamePreview.vue';

export default {
  name: "exame-sidebar",
  props: {
    exame: {
      type: Object,
      default: null,
      required: true
    },
    aba: {
      type: String,
      default: null
    }
  },
  components: {
    DadosExame,
    ExamesPacienteList,
    ImagensExame,
    LaudoPreview,
    HistoriaClinica,
    FormularioExamePreview,
  },
  data() {
    return {
      tab: "dados-exame",
    }
  },
  watch: {
    aba() {
      this.tab = this.aba;
    }
  },
  methods: {
    moment(data) {
      return moment(data)
    },
    tabColor(tab) {
      if (this.tab == tab) {
        return "primary darken-0";
      }
    },
    close() {
      this.$emit("close");
    },
  }, 
  computed: {
    idade() {
      return moment().diff(this.exame.solicitacao.paciente.dataNascimento, 'years');      
    }
  },
  mounted() {
    console.log("mounted", this.aba);
    this.tab = this.aba || 'dados-exame';
  },
};
</script>

<style scoped>
</style>
