<template>
  <div>
    <v-card-actions class="pt-3 pb-0">
      <v-chip class="mr-2" small color="primary"> {{ totalImagens }} {{ totalImagens > 1 ?
        'Imagens' :
        'Imagem' }} </v-chip>
      <v-chip class="mr-2" small color="primary" v-if="totalSeries > 0"> {{ totalSeries }} {{ totalSeries > 1 ?
        'Séries' :
        'Série' }} </v-chip>
      <v-chip class="mr-2" small color="primary" v-if="tamanhoArquivo > 0"> {{ tamanhoArquivo }}
        MB</v-chip>
      <v-spacer></v-spacer>
      <v-btn small text color="primary" :href="linkImagemRadiologica" target="_blank">
        <v-icon>mdi-file-download</v-icon>
        download</v-btn>
      <v-btn small link color="primary" :href="linkVisualizador" :target="`exame_${idConnect}`">
        <v-icon>mdi-image</v-icon>
        Visualizador</v-btn>
    </v-card-actions>

    <v-card v-for="(serie, idx) in file" :key="`${serie.serie}_${idx}`" flat>
      <v-card-title v-if="!hideSerie" class="primary--text">Serie: {{ serie.serie }}
        <v-subheader>
          {{ serie.arquivos.length }} imagens
        </v-subheader>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(a, aidx) in listaImages(idx)" :key="a" class="d-flex child-flex pa-1"
            :cols="cols"
          >
            <image-zoom v-if="limitarImagens(idx, aidx)"
              :src="`https://storage.googleapis.com/${nomeStorage}/${idConnect}/${a}`"
            ></image-zoom>
            <image-zoom v-else-if="expandir.includes(idx)"
              :src="`https://storage.googleapis.com/${nomeStorage}/${idConnect}/${a}`"
            ></image-zoom>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn v-if="calcImagens < serie.arquivos.length && !expandir.includes(idx)" @click="expandirImagens(idx)"
            text
            color="primary"
            class="text-none"
          >
            <span>Mostrar (+{{ serie.arquivos.length - calcImagens }} imagens)</span>
          </v-btn>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import ImageZoom from '../../../components/ImageZoom.vue';

export default {
  components: { ImageZoom },
  name: 'image-list',
  props: {
    exame: {
      type: Object,
      required: true
    },
    limiteExibirTodos: {
      type: Number,
      default: 20
    },
    cols: {
      type: Number,
      default: 2
    }, 
    hideSerie: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nomeStorage: null,
      idConnect: null,
      file: [],
      expandir: []
    }
  },
  methods: {
    moment(data) {
      return moment(data);
    },
    limitarImagens(idx, aidx) {
      //this.exame.study.totalImagens
      if (this.totalImagens < this.limiteExibirTodos) return true;
      // /this.file.serie[idx].arquivos.length
      if (aidx < this.calcImagens) return true;

      return false;
    },
    listaImages(idx) {

      let limite = this.calcImagens;
      if (this.expandir.includes(idx)) {
        limite = this.file[idx].arquivos.length
      }
      return this.file[idx].arquivos.slice(0, limite)
    },
    expandirImagens(idx) {
      this.expandir.push(idx)
    },
    getJsonFile(nomeStorage, idConnect) {
      const self = this;

      self.$googlestorage.get(`/${nomeStorage}/${idConnect}/files.json`)
        .then((response) => {
          self.loading = !self.loading
          let file = {...response.data[0]}
          
          file.arquivos = []
          if (self.hideSerie){
            response.data.forEach(serie => {                            
              file.arquivos.push(...serie.arquivos)
            });                        
            self.file.push(file)
          }else{
            self.file = response.data
          }          
          //console.log("aaaa", file.arquivos.slice(0,5));
        })
        .catch((e) => {
          console.log(e);
          // self.setAlerta({
          //   tipoAlerta: "error",
          //   textAlerta: "Erro ao buscar imagens",
          // });
          self.loading = !self.loading
        });
    },
  },
  computed: {
    linkVisualizador() {
      return `https://mviewer.mezzow.com/index.html?eid=${this.idConnect}&st=${this.nomeStorage}`
    },
    linkImagemRadiologica() {
      const storage = this.exame.study.nomeStorage
      const idConnect = this.exame.study.idConnect
      return this.exame.study.imagemRadiologica.replace(/http:/, "https:")
        || `https://storage.googleapis.com/${storage}/${idConnect}/${idConnect}.zip`
    },
    calcImagens(){
      return ((12 / this.cols))
    },
    totalImagens(){
      let count = this.file.reduce((total, s) => total + s.arquivos.length, 0)
      if (!count || count == 0) count = this.exame.study.totalImagens
      return count
    },
    tamanhoArquivo() {
      return Math.round(this.exame?.study?.tamanhoArquivo / 1024 / 1024)
    },
    totalSeries() {
      return this.file.length
    },
  },
  created() {
    this.nomeStorage = this.exame.study.nomeStorage;
    this.idConnect = this.exame.study.idConnect;
    this.getJsonFile(this.nomeStorage, this.idConnect);
  }  
}
</script>

<style>

</style>
