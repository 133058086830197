<template>
  <div>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="list-item-avatar-three-line"></v-skeleton-loader>


    <div class="pa-4" v-else>
      <v-card flat outlined class="" v-for="(exame) in exames" :key="exame.id">
        <v-card-title class="px-2">
          <v-row dense align="center">
            <v-col cols="auto">
              <span>Data: </span>
              <strong> {{ moment(exame.data).format('DD/MM/YYYY HH:mm') }}</strong>
            </v-col>
            <v-col cols="auto">
              <v-chip small color="primary" v-if="exame.tipoExame">{{ exame.tipoExame.nome }}</v-chip>
            </v-col>
            <v-col cols="auto">
              <v-chip small color="primary" v-if="exame.tipoExame">{{
                `${exame.tipoExame.modalidade.sigla}`
                }}</v-chip>
            </v-col>
            <v-col cols="auto">
              <v-chip small color="primary">{{ `${exame.statusExame.descricao}` }}</v-chip>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="toggerLaudo(exame.id)" v-if="isLaudoMezzow20(exame)"> Laudo
              <v-icon v-if="laudos.includes(exame.id)">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text v-if="laudos.includes(exame.id)" class="px-0">
          <laudo-preview :exame="exame"></laudo-preview>
        </v-card-text>
        <v-card-text v-if="exame.study && exame.study.dataEnvioJpg" class="px-0">
          <image-list :hideSerie="true" :cols="4" :exame="exame"></image-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from 'moment'
import ImageList from './ImageList.vue';
import LaudoPreview from './LaudoPreview.vue';

export default {
  components: { ImageList, LaudoPreview },
    name: 'exames-pacientes-list',
    props: {
      exame: {
        type: Object,
        required: true
      }
    },
    data() {
      return {    
        loading: true,    
        exames: [],
        laudos: []
      }
    },
    methods: {
      ...mapActions(["setAlerta"]),
      moment(data) {
        return moment(data)
      },
      loadModel() {
        const self = this;
        let url = `exame?max=99999`
        url += `&pacienteId=${self.exame.solicitacao.paciente.id}`

        self.$http.get(url)
          .then((response) => {
            self.loading = !self.loading
            self.exames = !response.data.empty ? response.data.content
              .filter(a => a.id != self.exame.id)
              .sort((a, b) => b.data - a.data) : []
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar exames anteriores",
            });
            self.loading = !self.loading
          });
      },      
      toggerLaudo(id) {
        if (this.laudos.includes(id)) {
          this.laudos = this.laudos.filter(a => a != id)
        }else{
          this.laudos.push(id)
        }
      },
      isLaudoMezzow20(item) {
        return [4,5,6].includes(item.statusExame.id) && item?.formularioExameLaudo?.id
      }
    }, 
    created() {
      this.loadModel()
    }
}
</script>

<style>

</style>
