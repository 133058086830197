<template>
  <v-card id="form-exame-preview" flat class="pa-5">
    <v-card>
      <v-card-text :class="formulariosExame.length > 0 ? 'pb-0' : ''" v-if="!!formularioExamePergunta">
        <v-card light flat :class="`${askColor} pt-4`">
          <div class="responsavel">
              <span>Por: <strong>{{ formularioExamePergunta.usuarioAlteracao.nomeExibicao }}</strong> em {{ dateFormat(formularioExamePergunta.dataCadastro) }}</span>
          </div>
          <div v-html="formularioExamePergunta.formHtml"></div>
        </v-card>
      </v-card-text>
      <div v-if="formulariosExame && formulariosExame.length % 2 == 0">
        <v-card-title v-if="formulariosExame.length > 0">
          <span class="headline primary--text">{{ tipoFormularioTitle }}</span>
        </v-card-title>
        <v-card-text justify="center" class="pb-5" v-for="idx of formulariosExame.length / 2" :key="idx">
          <v-card light>
            <v-col
              v-for="item in [formulariosExame[(2 * idx) - 2], formulariosExame[(2 * idx) - 1]]" :key="item.id" 
              :class="`pb-0 ${item.tipoFormulario.codigo == `${tipoFormularioCodigo}` ? askColor : answerColor}`"
            >
              <div class="responsavel">
                <v-col cols="auto">
                  <span>Por: <strong>{{ item.usuarioAlteracao.nomeExibicao }}</strong> em {{ dateFormat(item.dataCadastro) }}</span>
                </v-col>
              </div>
              <div v-html="item.formHtml"></div>
            </v-col>
          </v-card>
        </v-card-text>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { dateFormat } from '../../../utils/dateUtils';

export default {
  name: 'formulario-exame-preview',
  props: {
    formularioExame: {
      type: Object,
      required: true,
      default: () => null
    },
    bodyColor: {
      type: String,
      default: () => ''
    },
    askColor: {
      type: String,
      default: () => 'blue lighten-5'
    },
    answerColor: {
      type: String,
      default: () => 'blue lighten-4'
    },
  },
  data() {
    return {
      formulariosExame: [],
      formularioExamePergunta: null
    }
  },
  computed: {
    tipoFormularioCodigo() {
      const tipoFormulario = this.formularioExame?.tipoFormulario
      const codigo = tipoFormulario ? tipoFormulario.codigo.replace(/RESPOSTA_/, "") : ""
      return codigo
    },
    tipoFormularioTitle() {
      let text = ""
      switch (this.tipoFormularioCodigo) {
        case "RECONVOCACAO":
          text = "Reconvocações Anteriores"
          break;
        case "PENDENCIA":
          text = "Pendências Anteriores"
          break;
        case "REVISAO":
          text = "Revisões Anteriores"
          break;
        default:
          text = `Histórico`
          break;
      }
      return `${text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)}`
    }
  },
  methods: {
    ...mapActions(["setAlerta", "addNotifications"]),
    moment(data) {
      return moment(data)
    },
    async loadModel () {
      const self = this;
      const formularioExame = self.formularioExame

      if (formularioExame?.id) {
        await self.$http.get(`/formularioExame/${formularioExame.id}`)
          .then(async response => {
            const formularioExameGetted = response.data

            // Cria a lista que irá manter os registros de reconvocação ordenados para exibição
            if (formularioExameGetted) {
              await self.setFormularioExameInList(formularioExameGetted)
            }
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao abrir reconvocação",
            });
          })
      }
    },
    async setFormularioExameInList(formularioExame) {

      // Busca formulário html para renderização na tela
      const formHtml = await this.$http
        .get(`/report/form/preview/${formularioExame.formulario._id}`)
        .then(response => response.data)

      // Monta a lista de formulariosExame
      this.formulariosExame.push({...formularioExame, formHtml});
      if (formularioExame.formularioExamePai) {
        return this.setFormularioExameInList(formularioExame.formularioExamePai);
      }

      //TODO Deverá ser feito um ajuste na apresentacao      
      this.formulariosExame = [...this.formulariosExame.sort((a, b) => b.dataCadastro - a.dataCadastro)]

      // Remove o item do histórico caso a última ação seja uma reconvocação
      if (this.formulariosExame.length > 0 && this.formulariosExame.length % 2 != 0) {
        this.formularioExamePergunta = this.formulariosExame.splice(0, 1)[0]
      } else {
        this.formularioExamePergunta = null
      }

      // Organiza o histórico de reconvocações em pares de reconvocações e respostas
      for (let i = 0; i < this.formulariosExame.length - 1; i += 2) {
        [this.formulariosExame[i], this.formulariosExame[i + 1]] = [this.formulariosExame[i + 1], this.formulariosExame[i]]
      }
    },
    dateFormat(date, patternIn, patternOut) {
      if (!patternOut) patternOut = "DD/MM/YYYY HH:mm"
      return dateFormat(date, patternIn, patternOut)
    },
  },
  
  created() {
    this.loadModel()
  }
}
</script>

<style>
#form-exame-preview .responsavel {
  position: absolute;
  right: 16px;
  margin-top: -8px;
}
</style>
