<template>
  <div>
    <v-card flat class="">
      <v-card-title class="primary--text pb-1">
        Informações de Transferência
      </v-card-title>
      <v-card-text class="py-0">
        <v-row dense>
          <v-col cols="6">
            Chegada das imagens ao PACS: <strong>
              {{ moment(exame.study.dataDownloadPacs).format('DD/MM/YYYY HH:mm') }}
            </strong>
          </v-col>
          <v-col cols="6">
            Chegada do Exame:
            <strong>
              {{ moment(exame.study.dataEnvioSolicitacao).format('DD/MM/YYYY HH:mm') }}
            </strong></v-col>
          <v-col cols="6">
            Chegada das imagens na Nuvem:
            <strong>
              {{ moment(exame.study.dataEnvioNuvem).format('DD/MM/YYYY HH:mm') }}
            </strong></v-col>
          <v-col cols="6">
            Chegada das imagens JPG:
            <strong>
              {{ moment(exame.study.dataEnvioJpg).format('DD/MM/YYYY HH:mm') }}
            </strong></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <image-list :exame="exame" :cols="4"></image-list>
  </div>
</template>

<script>
import moment from 'moment'
import ImageList from './ImageList.vue';

export default {
  components: { ImageList },
  name: 'imagens-exame',
  props: {
    exame: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    moment(data) {
      return moment(data);
    },
  },
}
</script>

<style></style>
