<template>
  <v-menu
    :open-on-hover="openOnHover"
    top
    left
    absolute
    open-delay="500"
    close-delay="100"
    content-class="black"
    origin="center center"
    transition="scale-transition"
    v-resize="onResize"
    :disabled="disabledMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="image-container" @mousemove="zoom" @mouseleave="resetZoom" v-on="on" v-bind="attrs">
        <v-img :src="imageSrc" alt="Image" class="zoom-image" lazy-src="">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary lighten-3"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div v-if="!disabledMenu" class="zoom-lens" :style="lensStyle"></div>
      </div>
    </template>
    <div class="zoom-result" :style="resultStyle"></div>
  </v-menu>
</template>

<script>
export default {
  name: "image-zoom",
  props: ["src"],
  data() {
    return {
      imageSrc: this.src,
      lensStyle: {
        display: "none",
        position: "absolute",
        border: "1px solid #000",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        cursor: "none"
      },
      resultStyle: {
        display: "none",
        backgroundImage: "",
        backgroundSize: "200%", // Ajuste para controlar o nível de zoom
        backgroundRepeat: "no-repeat",
      },
      openOnHover: true,
      disabledMenu: false,
      hoverWidth: 0,
      hoverHeight: 0,
    };
  },
  methods: {
    zoom(event) {
      const img = event.currentTarget.querySelector(".zoom-image");
      const lens = event.currentTarget.querySelector(".zoom-lens");

      const pos = this.getCursorPos(event, img);
      const lensWidth = lens.offsetWidth / 2;
      const lensHeight = lens.offsetHeight / 2;

      let x = pos.x - lensWidth;
      let y = pos.y - lensHeight;

      // Impedir que a lente saia dos limites da imagem
      if (x > img.clientWidth - lens.offsetWidth)
        x = img.clientWidth - lens.offsetWidth;
      if (x < 0) x = 0;
      if (y > img.clientHeight - lens.offsetHeight)
        y = img.clientHeight - lens.offsetHeight;
      if (y < 0) y = 0;

      // Atualizar o estilo da lente
      this.lensStyle = {
        ...this.lensStyle,
        left: `${x}px`,
        top: `${y}px`,
        display: "block",

        width: `${img.clientWidth / 2}px`,
        height: `${img.clientHeight / 2}px`
      };

      // Calcula a proporcionalidade da imagem conforme o espaço disponível
      const totalHeight = this.hoverWidth * img.clientHeight / img.clientWidth 
      
      // Calcula a velociade do movimento do zoom em relação a lente
      const positionX = (this.hoverWidth / img.clientWidth) * 2
      const factorY = totalHeight - this.hoverHeight > 0 ? totalHeight - this.hoverHeight : 0
      const positionY = ((totalHeight + factorY) / img.clientHeight) * 2
      
      // Calcula as margens para preenchimento da tela caso seja retrato
      const diffHeight = totalHeight < this.hoverHeight ? this.hoverHeight - totalHeight : null

      // Atualizar o estilo do resultado do zoom
      this.resultStyle = {
        ...this.resultStyle,
        backgroundImage: `url(${this.imageSrc})`,
        backgroundPosition: `-${x * positionX}px -${y * positionY}px`,

        display: "block",
        'width': `${this.hoverWidth}px`,
        'height': `${totalHeight}px`,
        
        'max-width': `${this.hoverWidth}px`,
        'max-height': `${this.hoverHeight}px`,

        'margin-top': `${diffHeight ? diffHeight / 2 : ""}px`,
        'margin-bottom': `${diffHeight ? diffHeight / 2 : ""}px`,
      };
    },
    resetZoom() {
      this.lensStyle = {
        ...this.lensStyle,
        display: "none"
      };
      this.resultStyle = {
        ...this.resultStyle,
        display: "none"
      };
    },
    getCursorPos(event, img) {
      const rect = img.getBoundingClientRect();
      return {
        x: event.pageX - rect.left - window.pageXOffset,
        y: event.pageY - rect.top - window.pageYOffset
      };
    },
    onResize() {
      const timeout = 200
      setTimeout(() => {
        this.openOnHover = this.$vuetify.breakpoint.mdAndUp
        this.disabledMenu = !this.openOnHover
  
        const widthSideBar = document.getElementById("container-exame-sidebar")
        this.hoverWidth = window.innerWidth - widthSideBar.clientWidth - 60
  
        this.hoverHeight = window.innerHeight - 30
      }, timeout);
    }
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}
.zoom-lens {
  pointer-events: none;
}
.zoom-result {
  background-color: white;
}
</style>
