<template>
  <v-card flat>
    <v-card-actions v-if="possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN'])">
      <v-btn outlined color="primary" class="text-capitalize" :href="webAdminUrl" target="_blank">Editar Solicitação</v-btn>
    </v-card-actions>

    <v-card-title class="primary--text pb-1">
      Unidade
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="auto">
          Empresa:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.setor.unidade.empresa.nome }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Unidade:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.setor.unidade.nome }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Telefone:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.setor.unidade.telefone }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          E-mail:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.setor.unidade.email }}</strong></v-col>
      </v-row>
    </v-card-text>

    <v-card-title class="primary--text pb-1">
      Paciente
    </v-card-title>
    <v-card-text>
      <v-row dense v-if="exame.solicitacao.paciente.patientId">
        <v-col cols="auto">
          Paciente ID:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.patientId }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Nome:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.nome }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Sexo:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.sexo }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Data Nascimento:
        </v-col>
        <v-col cols="auto"><strong>{{ moment(exame.solicitacao.paciente.dataNascimento).format("DD/MM/YYYY")
            }}</strong></v-col>
      </v-row>
      <v-row dense v-if="exame.solicitacao.paciente.cpf">
        <v-col cols="auto">
          CPF:
        </v-col>
        <v-col cols="auto"><strong>{{ getMask(exame.solicitacao.paciente.cpf) }}</strong></v-col>
      </v-row>
      <v-row dense v-if="exame.solicitacao.paciente.rg">
        <v-col cols="auto">
          RG:
        </v-col>
        <v-col cols="auto"><strong>{{ getMask(exame.solicitacao.paciente.rg) }}</strong></v-col>
      </v-row>
      <v-row dense v-if="exame.solicitacao.paciente.email">
        <v-col cols="auto">
          E-mail:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.email }}</strong></v-col>
      </v-row>
      <v-row dense v-if="exame.solicitacao.paciente.telefone || exame.solicitacao.paciente.celular">
        <v-col cols="auto">
          Telefone:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.telefone }} </strong></v-col>
        <v-col cols="auto"><strong>{{ exame.solicitacao.paciente.celular}}</strong></v-col>
      </v-row>
    </v-card-text>
    <v-card-title class="primary--text pb-1">
      Exame
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="auto">
          ID:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.id }}</strong></v-col>
      </v-row>
      <v-row dense v-if="exame.numAcesso">
        <v-col cols="auto">
          AN:
        </v-col>
        <v-col cols="auto"><strong>{{ exame.numAcesso }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Tipo Exame:
        </v-col>
        <v-col cols="auto"><strong v-if="exame.tipoExame">{{ exame.tipoExame.nome }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Data:
        </v-col>
        <v-col cols="auto"><strong>{{ moment(exame.data).format('DD/MM/YYYY HH:mm') }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Medico Solicitante:
        </v-col>
        <v-col cols="auto"><strong v-if="exame.solicitacao.medicoSolicitante">
            {{ exame.solicitacao.medicoSolicitante.nome }} - {{ exame.solicitacao.medicoSolicitante.registroConselho
            }}</strong></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="auto">
          Data Solicitação:
        </v-col>
        <v-col cols="auto">
          <strong>{{ moment(exame.solicitacao.data).format('DD/MM/YYYY HH:mm') }}</strong>
        </v-col>
      </v-row>

    </v-card-text>

  </v-card>


</template>

<script>
import moment from 'moment'
import { possuiPermissao } from '../../../seguranca';

export default {
  name: 'dados-exame',
  props: {
    exame: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    webAdminUrl() {
      return `${process.env.VUE_APP_MEZZOW_WEB_ADMIN_URL}solicitacao/edit/${this.exame.solicitacao.id}`;
    },
  },
  methods: {
    moment(data) {
      return moment(data);
    },
    possuiPermissao(value) {
      return possuiPermissao(value)
    },
    getMask(value) {
      return `${value.substring(0, value.length - 5).replace(/[0-9]/g, "*")}${value.substring(value.length - 5)}`
    },
  }
}
</script>

<style></style>
