<template>
  <div v-if="exameShow">
    <v-dialog v-model="dialog" persistent max-width="1024">
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card outlined>
          <v-card-title v-if="!titleOff">
            <v-tooltip open-delay="800" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="primary" icon @click="close" v-bind="attrs" v-on="on">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>  
              </template>
              <span>Sair</span>
            </v-tooltip>
            <span class="headline primary--text ml-2">História Clínica</span>
          </v-card-title>
          <v-card-text v-if="!headersOff">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-tooltip open-delay="800" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-on="!isItToday(exameShow.data) ? on : false"
                      v-bind="attrs"
                    >{{ getDateFormatted(exameShow.data) }}</span>
                  </template>
                  <span> {{ getDateFormattedDisplay(exameShow.data) }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">{{ $_.startCase(exameShow.solicitacao.paciente.nome.toLowerCase()) }}</v-col>
              <v-col cols="12" md="4">{{ exameShow.solicitacao.setor.unidade.nome }}</v-col>
              <v-col cols="12" md="2" class="text-center">
                <v-chip dark small label style="width: 125px" class="justify-center"
                  :color="statusColor(`${exameShow.statusExame.id}`) + ' white--text'"
                >
                  <span>{{ exameShow.statusExame.descricao }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pb-0">
            <v-row dense class="mr-1">
              <v-col cols="12" md="12" v-if="modeloFormularios">
                <v-chip-group
                  active-class="blue blue--text text--accent-4"
                  v-model="modeloFormulario"
                  mandatory
                  column
                >
                  <v-chip v-for="modelo in modeloFormularios" :key="modelo._id"
                    :value="modelo"
                    outlined
                    label
                    :ripple="false"
                  >
                    {{ modelo.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" md="12">
                <formulario-create-edit v-if="existsFormularioExame" ref="formView"
                  :key="formularioExameHistoriaClinica.formulario._id + '_' + formularioExameHistoriaClinica.formulario.nome"
                  :formularioParam="formularioExameHistoriaClinica.formulario"
                  :storageNameParam="exameShow.solicitacao.setor.unidade.storage"
                  :saveBottonOff="true"
                  :autoSaveUpdate="false"
                  :formularioRascunho="false"
                  v-model="executeSaveUpdateForms"
                  @confirmSaveForms="returnSaveUpdateForms"
                ></formulario-create-edit>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 pr-4">
            <v-spacer></v-spacer>
            <v-btn color="error" class="text-capitalize" outlined v-if="!cancelBottonOff" @click="close">Sair</v-btn>
            <v-btn color="primary" class="text-capitalize" outlined type="submit">{{textSubmit}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormularioCreateEdit from '../../components/forms/FormularioCreateEdit.vue';
import { getModeloFormulariosByTipoFormularioCodigo } from '../../utils/formUtils'
import { getDateFormatted, getDateFormattedDisplay, isItToday } from '../../utils/dateUtils';

export default {
  name: "exame-historia-clinica-create-edit",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    titleOff: {
      type: Boolean,
      default: false,
    },
    headersOff: {
      type: Boolean,
      default: false,
    },
    cancelBottonOff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      exame: null,
      exameShow: null,
      modeloFormulario: null,
      modeloFormularios: [],
      executeSaveUpdateForms: false,

      formularioExameHistoriaClinica: {
        tipoFormulario: {},
        formulario: {},
      },

      textSubmit: "Adicionar"
    };
  },
  watch: {
    value(value) {
      this.exame = value;
    },
    async exame(value) {
      this.$emit("input", value);
      if (value) {
        await this.getModeloFormulaios()
        await this.getExameFull()
        this.dialog = true
      }
    },
    modeloFormulario(value) {
      if (value) this.changeModeloFormulario()
    }
  },
  components: {
    FormularioCreateEdit
  },
  computed: {
    existsFormularioExame() {
      return this.formularioExameHistoriaClinica?.formulario?.modeloFormulario?._id
    }
  },
  methods: {
    ...mapActions(["setAlerta"]),
    isItToday(date) {
      return isItToday(date)
    },
    getDateFormatted(date) {
      return getDateFormatted(date)
    },
    getDateFormattedDisplay(date) {
      return getDateFormattedDisplay(date)
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : "";
    },
    async getExameFull() {
      const self = this
      await self.$http.get(`/exame/${this.exame.id}`)
        .then(response => {
          const exame = response.data
          if (!exame?.solicitacao?.setor?.unidade?.storage) {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: `A unidade ${exame?.solicitacao?.setor?.unidade?.nome} não possui storage cadastrada. Entre em contato com o Administrador!`,  
            })
            return
          }
          self.exameShow = exame
          
          const form = self.exameShow?.formularioExameHistoriaClinica?.formulario
          if (!!form?._id && !!form?.modeloFormulario?._id) {
            const modeloFormularioId = form.modeloFormulario._id
            self.modeloFormulario = self.modeloFormularios.find(m => m._id == modeloFormularioId)

            self.textSubmit = "Alterar"
          }
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível buscar o exame!",
          })
        })
    },
    // Busca os modeloFormularios permitidos para a unidade e ativos
    async getModeloFormulaios() {
      const self = this;
      
      // Busca modeloFormularios ativos pelos código do tipoFormulario e pelo id da unidade (unidadeId is optional)
      const CODIGO_TIPO_FORMULARIO = "HISTORIA_CLINICA"
      self.modeloFormularios = await getModeloFormulariosByTipoFormularioCodigo(CODIGO_TIPO_FORMULARIO, self.exame?.solicitacao?.setor?.unidade?.id, self.exame?.tipoExame?.id)
      if (!self.modeloFormularios || self.modeloFormularios.length == 0) {
        self.setAlerta({
          tipoAlerta: "warning",
          textAlerta: "Não foram encontrados modelos de História Clínica!",
        })
      }
    },
    changeModeloFormulario() {
      const form = this.exameShow?.formularioExameHistoriaClinica?.formulario
      const isThereFormulario = !!form?._id && !!form?.modeloFormulario?._id
      if (isThereFormulario) {
        if (this.modeloFormulario?._id == form.modeloFormulario._id) {
          // Monta o formularioExame para história clínica
          this.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, tipoFormulario: form.tipoFormulario, formulario: JSON.parse(JSON.stringify(form))}
          return
        } else {
          const formModelo = { ...this.modeloFormulario, _id: form._id, modeloFormulario: { _id: this.modeloFormulario._id }}
          this.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, tipoFormulario: form.tipoFormulario, formulario: JSON.parse(JSON.stringify(formModelo))}
          return
        }
      }

      // Realiza a mudança do formularioModelo
      this.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, tipoFormulario: this.modeloFormulario.tipoFormulario }
      
      const formularioModelo = { ...this.modeloFormulario, _id: null, modeloFormulario: { _id: this.modeloFormulario._id }}
      this.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, formulario: JSON.parse(JSON.stringify(formularioModelo))}
      return
    },
    async returnSaveUpdateForms(formulario) {     
      if (formulario && formulario._id) {

        // // Function that set the last formularioExame in chain
        // const setFormularioExame = async (formularioExame, newFormularioExame) => {
        //   if (formularioExame?.formularioExamePai?.id) {
        //     setFormularioExame(formularioExame.formularioExamePai, newFormularioExame)
        //   } else {
        //     // Sets the last item in the chain to new item
        //     // formularioExame = { ...formularioExame, newFormularioExame }

        //     // Sets a new item in chain`s last item
        //     formularioExame.formularioExamePai = newFormularioExame
        //   } 
        //   return formularioExame
        // }
        // // Charge the new formularioExameHistoriaClinica
        // this.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, formulario: formulario }
        // if (this.exameShow?.formularioExameHistoriaClinica) {
        //   await setFormularioExame(this.exameShow.formularioExameHistoriaClinica, this.formularioExameHistoriaClinica)
        // } else {
        //   this.exameShow.formularioExameHistoriaClinica = this.formularioExameHistoriaClinica
        // }

        // Substitui formularioExameHistoriaClinica ao invés de criar formularioExamePai (novas versões).
        this.exameShow.formularioExameHistoriaClinica = { ...this.formularioExameHistoriaClinica, formulario: formulario}
        
        this.saveUpdate()
      }
    },
    saveUpdate() {
      const self = this

      if (self.exameShow.id) {
        self.$http.put('/exame', self.exameShow)
          .then(async response => {
            self.$emit('objectReturn', await self.$http.get(`/exame/${response.data.id}`).then(response => response.data))
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'História Clínica incluída com sucesso',
            })
            this.close();
          })
          .catch(async () => {
            // Delete the created formulario
            await self.$http.delete(`/forms/${self.formularioExameHistoriaClinica.formulario._id}`)  

            self.setAlerta({
              tipoAlerta : 'error',
              textAlerta : 'Erro ao alterar exame',
            })
          })
      }
    },
    close() {
      this.exame = null
      this.exameShow = null
      this.modeloFormulario = null
      this.dialog = false

      this.formularioExameHistoriaClinica = {
        formulario: {}
      }
    },
    onSubmit() {
      const self = this
      
      if(!self.$refs.form.validate() || !self.$refs.formView.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
        return
      }

      self.executeSaveUpdateForms = true
    }
  },
};
</script>