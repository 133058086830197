<template>
  <v-card flat class="pa-5">
    <v-card>
      <v-card-title class="primary--text pb-1">
        <v-chip small color="primary"> {{ exame.statusExame.descricao }}</v-chip>

        <v-spacer></v-spacer>
        <v-col cols="auto" class="py-0 pr-2" v-if="!resultadoExame || exame.statusExame.id === 6">
          <v-btn v-if="!loadingPdf" small link color="primary" @click="getPdf()">
            <v-icon>mdi-file-pdf-box</v-icon>Visualizar
          </v-btn>
          <div v-else>
            <span class="mr-1">Carregando...</span>
            <v-progress-circular :size="24" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-btn v-if="resultadoExame" small link color="primary" :href="resultadoExame.link" target="_blank">
            <v-icon>mdi-file-check</v-icon>Resultado
          </v-btn>
        </v-col>
      </v-card-title>
      <div v-html="html">
      </div>
      <v-card-text class="px-5">

        <v-divider></v-divider>

        <template v-if="exameFull.statusExame && exameFull.formularioExameLaudo">
          <div v-if="[4,5].includes(exameFull.statusExame.id) && exameFull.formularioExameLaudo.medicoPrimeiraAssinatura.id" class="subtitle-2 pt-2">          
            Assinado por Dr(a) {{ exameFull.formularioExameLaudo.medicoPrimeiraAssinatura.nomeExibicao }} em
            {{ moment(exameFull.formularioExameLaudo.dataPrimeiraAssinatura)
            .format("DD/MM/YYYY HH:mm:ss") }}
          </div>
          <div v-else-if="exameFull.statusExame.id == 6 && exameFull.formularioExameLaudo.usuarioAlteracao.id" class="subtitle-2 pt-2">
            Relatório preliminar por Dr(a) {{ exameFull.formularioExameLaudo.usuarioAlteracao.nomeExibicao }} em
            {{ moment(exameFull.formularioExameLaudo.dataCadastro)
            .format("DD/MM/YYYY HH:mm:ss") }}
          </div>

          <div class="subtitle-2" v-if="exameFull.formularioExameLaudo.medicoSegundaAssinatura.id">
            Coassinado por Dr(a) {{ exameFull.formularioExameLaudo.medicoSegundaAssinatura.nomeExibicao }} em
            {{ moment(exameFull.formularioExameLaudo.dataSegundaAssinatura)
            .format("DD/MM/YYYY HH:mm:ss") }}
          </div>
        </template>

      </v-card-text>
    </v-card>

  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import Notification from "../../../models/Notification"

export default {
  name: 'laudo-preview',
  props: {
    exame: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingPdf: false,
      html: "",
      exameFull: {},
      resultadoExame: null
    }
  },
  methods: {
    ...mapActions(["setAlerta", "addNotifications"]),
    moment(data) {
      return moment(data)
    },
    getPage() {
      var self = this
      const exameId = self.exame?.id
      const formularioId = self.exame?.formularioExameLaudo?.formulario?._id
      
      if (!exameId) return
      if (!formularioId) return

      // self.$http.get(`/report/form/preview/${formularioId}`) -- Renderiza somente o formularioExameLaudo.formulario
      self.$http.get(`/report/form-exame/preview/${exameId}`) // Renderiza o formularioExameLaudo e seus adendos
        .then((response) => {
          self.loading = true
          self.html = response.data;
        })
        .catch((e) => {
          console.error(e);
          self.loading = false
        });

    },
    sendNotification() {
      var self = this
      self.loadingPdf = true
      const callback = self.$http.get(`/report/form-exame/pdf/${self.exame.id}`, { responseType: 'arraybuffer' })
        .then((response) => {
          self.loadingPdf = false

          const blob = new Blob([response.data], { type: 'application/pdf' });
          this.linkPdf = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.id = "Laudo2.0";
          link.target = `laudo_${self.exame.id}`
          link.href = this.linkPdf;
          return new Notification("pdf", "Relatório gerado com sucesso", link, this.$options.name)
        })
        .catch((e) => {
          self.loadingPdf = false
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: e?.response?.data?.message || "Não foi possível gerar o relatório!"
          })
          return new Notification("error", "Não foi possível gerar o relatório!", e, this.$options.name)
        });

      // Send function to state variable notifications
      self.addNotifications(callback)
    },
    getPdf() {
      var self = this
      self.loadingPdf = true
      self.$http.get(`/report/form-exame/pdf/${self.exame.id}`, { responseType: 'arraybuffer' })
        .then((response) => {
          self.loadingPdf = false

          const blob = new Blob([response.data], { type: 'application/pdf' });
          this.linkPdf = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.id = "Laudo2.0";
          link.target = `laudo_${self.exame.id}`
          link.href = this.linkPdf;
          // link.download = 'file.pdf'; // Name of the downloaded file
          link.click();

          // Clean up by revoking the Object URL
          // URL.revokeObjectURL(this.linkPdf);
        })
        .catch((e) => {
          self.loadingPdf = false
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: e?.response?.data?.message || "Não foi possível gerar o relatório!"
          })
        });
    },
    getExame() {
      // buscar pelo perfil é 7x mais rapido que o exame, avaliar a necessidade depois
      const self = this
      self.$http.get(`/exame/${this.exame.id}`)
        .then(response => {
          self.exameFull = response.data
          self.getResultadoExame(self.exameFull)
        })
        .catch(() => {})
    },
    getResultadoExame(exame) {
      const self = this
      self.resultadoExame = [...exame.resultados].sort((a, b) => b.id - a.id).find(a => a.idResultado)
      if (self.resultadoExame) {
        self.resultadoExame.link = `${process.env.VUE_APP_MEZZOW_RESULTADO_URL}/#resultado/${self.resultadoExame.idResultado ? self.resultadoExame.idResultado : ""}`
      }
    },
  },
  
  created() {
    this.getPage()
    this.getExame()
  }
}
</script>

<style></style>
