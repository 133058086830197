class Notification {
  constructor (type, message, data, source, filter) {
    this.type = type
    this.data = data
    this.message = message
    this.source = source
    this.filter = filter
    this.dateCreated = new Date()
  }
}

module.exports = Notification